import React from "react";
import styled from "styled-components";

const ImageWrapper = styled.img`
  width: 100vw;
  height: 300px;
  object-fit: cover;
`;

const AboutText = styled.p`
  margin: 3rem;
  text-align: center;
`;

const Top: React.FC = () => {
  return (
    <div>
      <ImageWrapper src="/images/inuta_header.webp" alt="犬田のイラスト" />
      <AboutText>
        お問い合わせなどは<a href="https://twitter.com/yoigara3">@yoigara3</a>
        まで
      </AboutText>
    </div>
  );
};
export default Top;
